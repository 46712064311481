<template>
    <div class="tbody-row" :id="`selectable-card-collapse-${index}`">
        <div
            class="tbody-row__item"
            v-bind:key="sindex + '' + settingsItem.computeFn(data[settingsItem.key])"
            v-for="(settingsItem, sindex) in settings.header"
            :style="{
                color: settingsItem.bodyColor,
                flex: settingsItem.columnWidth,
                whiteSpace: settingsItem.whiteSpace,
                fontSize: settingsItem.fontSize,
                textAlign: settingsItem.textAlign,
            }"
        >
            <div class="item__name-wrapper">
                <span class="label-type" v-if="settingsItem.type && settingsItem.type === 'label'">
                    {{ settingsItem.computeFn(data[settingsItem.key]) }}
                </span>

                <span class="label-action-type" v-else-if="settingsItem.type && settingsItem.type === 'label-action'">
                    <span @click="settingsItem.action( data )">
                        {{ settingsItem.computeFn(data[settingsItem.key]) }}
                    </span>
                </span>

                <div class="tags-type" v-else-if="settingsItem.type && settingsItem.type === 'tags'">
                    <TagButton
                        :label="tagItem"
                        :key="tagIdx"
                        v-for="(tagItem, tagIdx) in settingsItem.computeFn(data[settingsItem.key])"
                    />
                </div>

                <div
                    v-else
                    v-html="settingsItem.computeFn(data[settingsItem.key])"
                ></div>

                <span class="toggle-button" v-if="settingsItem.toggleBtn" @click="toggle(index)">
                    {{ labels.detail }}
                    <font-awesome-icon icon="caret-down" />
                </span>

                <div class="collapse-body">
                    <TextCard
                        class="mt-3"
                        v-if="settingsItem.textCard"
                        :title="settingsItem.textCard.title"
                        :text="settingsItem.textCard.computeFn(data[settingsItem.textCard.key])"
                    />

                    <LabelButtonCard
                        v-if="settingsItem.contentCard && data.content"
                        :list="data.content.list"
                        :headerTitle="data.content.title"
                    />
                </div>
            </div>
        </div>
        <div class="tbody-row__btn" :style="{ flex: settings.actionsWidth }">
            <div
                class="btn-wrapper"
                v-for="(btnItem, btnIdx) in settings.actions"
                :key="btnIdx"
                @click="btnItem.action({ ...data, index: index })"
            >
                <Button
                    :variant="btnItem.variant"
                    :label="btnItem.label"
                    :styles="btnItem.styles"
                    v-show="btnItem.show === undefined || (btnItem.show && btnItem.show(data))"
                />
            </div>

            <LabelButtonCard
                v-if="checkLabelBtnCard"
                :header-title="settings.contentCard.title"
                :list="settings.contentCard.computeFn(data[settings.contentCard.key])"
            />
        </div>
    </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import TagButton from "@/components/Buttons/TagButton";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import TextCard from "@/components/Cards/TextCard";

export default {
    name: "CollapsibleTableRow",
    components: {
        Button,
        TagButton,
        LabelButtonCard,
        TextCard,
    },
    props: {
        settings: {
            type: Object,
            default: () => ({
                header: [],
                actions: [],
            }),
        },
        data: {
            type: Object,
            default: () => {},
        },
        labels: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        checkLabelBtnCard() {
            if (
                this.settings.contentCard &&
                this.settings.contentCard.computeFn(this.data[this.settings.contentCard.key]) &&
                this.settings.contentCard.computeFn(this.data[this.settings.contentCard.key]).length
            ) {
                return true;
            }
            return false;
        },
    },
	methods: {
		toggle( idx ){
			let element = document.getElementById(`selectable-card-collapse-${idx}`);

			if ( element.classList.contains('expanded')) {
				element.classList.remove('expanded');
			} else {
				element.classList.add('expanded');
			}
		},
	},
};
</script>

<style lang="scss" scoped>

.tbody-row {
    padding: 5px 15px;
    overflow: hidden;
    user-select: none;
    @include transition--ease-out;

    &:not(:last-child) {
        border-bottom: 1px solid $light_gray;
    }

    &__item {
        .item__name-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
        }
        
        .collapse-body {
            display: none;
        }
        .toggle-button {
            display: inline-block;
            font-family: "Oswald", sans-serif;
            font-size: 0.875rem;
            color: $light_text;
            margin-left: 15px;
            cursor: pointer;

            svg {
                margin-left: 5px;
            }
        }

        ::v-deep .label-button-card {
            &__header {
                text-transform: none;
            }
        }

        .label-action-type {
            display: inline-block;
            width: 70%;
            cursor: pointer;
        }
    }

    &.expanded {
        .item__name-wrapper {
            .collapse-body {
                display: block;
                width: 100%;
            }
        }
    }

    &__btn {
        .btn-wrapper {
            display: flex;
            justify-content: flex-end;

            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }

    .base-card {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
</style>