<template>
  <div class="selectable-table" :style="style">
    <div class="selectable-table__thead">
      <div
        class="selectable-table__thead__column"
        v-bind:key="index"
        v-for="(item, index) in settings.header"
        v-bind:style="{ color: item.headerColor, flex: item.columnWidth }"
      >
        {{ item.headerLabel }}
      </div>
      <div
        class="selectable-table__thead__btn"
        :style="{ flex: settings.actionsWidth }"
      >
        {{ settings.actionsHeaderLabel }}
      </div>
    </div>

    <div class="selectable-table__tbody">
      <CollapsibleTableRow
        v-for="(dataItem, dindex) in dataArray"
        :key="dindex"
        :index="dindex"
        :data="dataItem"
        :settings="settings"
        :labels="labels"
      />
    </div>
  </div>
</template>

<script>
import CollapsibleTableRow from "@/components/Table/CollapsibleTableRow";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
library.add(faCaretDown);

export default {
  name: "CollapsibleTable",
  components: {
    CollapsibleTableRow,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({
        header: [],
        actions: [],
      }),
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      style: {
        "--min-table-width": this.settings.minTableWidth,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.selectable-table {
  overflow-x: auto !important;
  border: 1px solid $light_gray;
  border-radius: 5px;

  &::-webkit-scrollbar {
    height: $scrollbar_height;
  }
  &::-webkit-scrollbar-track {
    background: $light_gray;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $navy;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

  &__thead,
  .tbody-row {
    display: flex;
    align-items: flex-start;
    gap: 25px;
    min-width: var(--min-table-width);
  }

  &__thead {
    font-family: "Oswald", sans-serif;
    border-bottom: 1px solid $light_gray;
    padding: 15px;
    text-transform: uppercase;
    font-weight: 400;
  }

  // &__tbody {}
}
</style>
