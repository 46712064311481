<template>
  <FormulateForm id="create-sub-module-submit" class="first-step">
    <FormulateInput
      type="text"
      name="title"
      class="horizontal-label-input"
      :label="labels.title"
      v-model="data.title"
      error-behavior="blur"
      validation="required"
      :validation-messages="{
        required: `${labels.title} ${labels.required.toLowerCase()}`,
      }"
    />
    <div class="row">
      <div class="col-md-12">
        <div class="description-title">{{ labels.description }}</div>
        <FormulateInput
          type="text-editor"
          name="description"
          :value="data.description"
          error-behavior="blur"
          validation="required"
          :validation-messages="{
            required: `${labels.description} ${labels.required.toLowerCase()}`,
          }"
          @changed="textEditorChanged"
        />

        <FormulateInput
          v-if="showExceptAndFree"
          type="textarea"
          name="excerpt"
          :label="labels.excerpt"
          v-model="data.excerpt"
          error-behavior="blur"
          validation="required"
          :validation-messages="{
            required: `${labels.excerpt} ${labels.required.toLowerCase()}`,
          }"
        />

        <Checkbox
          v-if="
            showExceptAndFree &&
            (modulePageData.hasRole('super') ||
              this.modulePageData.hasRole('creator'))
          "
          :disabled="false"
          :label="labels.free"
          :value="data.free"
          name="free"
          @changed="checkboxChanged"
        />
      </div>
    </div>
  </FormulateForm>
</template>

<script>
// import Card from "@/components/Cards/Card.vue";
import Checkbox from "@/components/Input/Checkbox.vue";

const colors = require("/colors.config.json");

export default {
  components: {
    // Card,
    Checkbox,
  },
  props: {
    labels: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
    cardColor: {
      type: String,
      default: `${colors.white}`,
    },
    showExceptAndFree: {
      type: Boolean,
      default: true,
    },
    modulePageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // formData: this.data
    };
  },
  methods: {
    textEditorChanged(value) {
      this.data.description = value;
    },
    checkboxChanged(value) {
      this.data.free = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.description-title {
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 0.1em;
}
</style>
