<template>
	<div class="row">
		<div class="col-md-4" v-for="( item, idx ) in list" :key="idx">
			<SelectableCard
				class="h-100"
				:index="idx"
				:image="item.image"
				:title="item.title"
				:description="item.description"
				:selected="selected === idx"
				:action="action"
                :btn-disabled="item.btnDisabled"
			/>
		</div>
	</div>
</template>

<script>
import SelectableCard from "@/components/Cards/SelectableCard.vue";

import { mapState } from 'vuex';

export default {
    components: {
        SelectableCard,
    },
    props: {
        labels: {
            type: Object,
            default: () => {}
        },
        selected: {
            type: Number,
            default: 0
        },
        action: {
            type: Function,
            default: () => {}
        },
    },
    computed: {
        ...mapState( "helper", {
			admin_roles: 'admin_roles',
		}),
        isLinker() {
			if (Array.isArray(this.admin_roles)) return this.admin_roles.includes('linker');
            
            return false;
        },
        list() {
            let result = [
                {
                    image: "assets/logo.png",
                    title: this.labels.original,
                    description: this.labels.original_content_description
                }
            ];

            if (this.isLinker) result = [
                ...result,
                ...[
                    {
                        image: "assets/logo.png",
                        title: this.labels.link,
                        description: this.labels.link_content_description,
                        btnDisabled: false
                    },
                    {
                        image: "assets/logo.png",
                        title: this.labels.copy,
                        description: this.labels.copy_content_description,
                        btnDisabled: false
                    }
                ]
            ];

            return result;
        }
    },
}
</script>

<style lang="scss" scoped>
</style>