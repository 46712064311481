<template>
	<BaseCard class="text-card" padding="15px">
		<div class="text-card__title">
			{{ title }}
		</div>
		<div class="text-card__text" v-html="text"></div>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

export default {
	name: 'TextCard',
	components: {
		BaseCard,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
	},
}
</script>

<style lang='scss' scoped>

.text-card {
	white-space: break-spaces;
	color: $dark;
	
	&__title {
		font-family: 'Oswald', sans-serif;
		margin-bottom: 15px;
	}

	&__text {
		text-align: justify;
		font-size: .875rem;
		padding-left: 4px;
		padding-right: 4px;
	}
}

</style>