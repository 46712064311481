<template>
	<BaseCard class="selectable-card">

		<!-- <img class="selectable-card__image" :src="require(`@/${image}`)" alt=""> -->

		<div class="selectable-card__title">{{ title }}</div>

		<div class="selectable-card__description">{{ description }}</div>

		<div class="selectable-card__btn" @click="action( index )">
			<Button
				:label="btnLabel"
				:icon="iconName"
				variant="btn-purple"
				style="fontWeight: 600"
                :disabled="btnDisabled"
                :class="{'disabled': btnDisabled}"
			/>
		</div>

	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import Button from '@/components/Buttons/Button';

import { library } from "@fortawesome/fontawesome-svg-core";
import { customCircleCheck } from "@/assets/icons";
library.add( customCircleCheck );

export default {
	name: 'SelectableCard',
	components: {
		BaseCard,
		Button,
	},
	props: {
		index: {
			type: Number,
			default: 0
		},
		image: {
			type: String,
			default: ''
		},
		title: {
			type: String,
			default: ''
		},
		description: {
			type: String,
			default: ''
		},
		btnLabels: {
			type: Object,
			default: () => ( { select: 'Select', selected: 'Selected' } )
		},
        btnDisabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: 'custom-circle-check'
		},
		action: {
			type: Function,
			default: () => {}
		},
		selected: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		btnLabel() {
			let output = this.btnLabels.select;
			if ( this.selected ) output = this.btnLabels.selected;
			return output;
		},
		iconName() {
			let output = '';
			if ( this.selected ) output = this.icon;
			return output;
		},
	},
}
</script>

<style lang='scss' scoped>

.selectable-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	&__title {
		font-weight: 600;
	}

	&__btn {
		padding: 7px 20px;
	}

	&__image,
	&__title,
	&__description {
		margin-bottom: 15px;
	}

    .disabled {
        opacity: .5;
        cursor: not-allowed;
    }
}

</style>