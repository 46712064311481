<template>
	<span class="tag-button">
		{{ label }}
	</span>
</template>

<script>
export default {
	name: 'TagButton',
	props: {
		label: {
			type: String,
			default: ''
		},
	},
}
</script>

<style lang='scss' scoped>

.tag-button {
	font-family: 'Oswald', sans-serif;
	font-size: .875rem;
	font-weight: 500;
	display: inline-block;
	background-color: $background_3;
	padding: 4px 10px;
	margin: 5px 3px;
	border-radius: 3px;
}

</style>