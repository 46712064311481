<template>
  <div class="select-content">
    <div class="select-content__top">
      <Dropdown
        class="generate-entity__dropdown"
        :placeholder="labels.monetization_bundle"
        :list="MBList"
        :value="MBValue"
        @changed="dropDownChanged"
      />
    </div>
    <div class="select-content__parents" v-if="parents">
      <h5
        @click="loadParent(parent._id)"
        v-for="(parent, index) in parents"
        :key="index"
      >
        {{ parent.title }}{{ breadcrumbSymbol(parent, index) }}
      </h5>
    </div>
    <CollapsibleTable
      :settings="settings"
      :data-array="moduleList"
      :labels="labels"
    />
  </div>
</template>

<script>
import CollapsibleTable from "@/components/Table/CollapsibleTable.vue";
import Dropdown from "@/components/Dropdowns/Dropdown";
const colors = require("/colors.config.json");
import { mapActions } from "vuex";

export default {
  components: {
    CollapsibleTable,
    Dropdown,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      MBValue: this.$route.query["monetization-bundle"] || "",
      MBList: [],
      moduleList: [],
      parents: [],
    };
  },
  computed: {
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.title,
            headerColor: `${colors.gray_text}`,
            key: "title",
            type: "label-action",
            toggleBtn: true,
            textCard: {
              title: this.labels.description,
              key: "description",
              computeFn: (input) => input,
            },
            action: (item) => {
              if (
                this.$route.query.parent !== item._id &&
                item.modules?.length
              ) {
                this.$router.push({
                  query: {
                    parent: item._id,
                    monetization: this.$route.query.monetization,
                  },
                });
              }
            },
            computeFn: (input) => input,
            bodyColor: `${colors.dark}`,
            columnWidth: "1 1 35%",
          },
          {
            headerLabel: this.labels.tags,
            headerColor: `${colors.gray_text}`,
            key: "tags",
            type: "tags",
            computeFn: (input) => input,
            bodyColor: `${colors.gray_text_2}`,
            columnWidth: "1 1 20%",
          },
          {
            headerLabel: this.labels.authors,
            headerColor: `${colors.gray_text}`,
            key: "authors",
            type: "tags",
            computeFn: (input) => input,
            bodyColor: `${colors.gray_text_2}`,
            columnWidth: "1 1 20%",
          },
        ],
        minTableWidth: "300px",
        actionsWidth: "0 0 25%",
        actionsHeaderLabel: "",
        contentCard: {
          title: this.labels.content_used,
          key: "used",
          computeFn: (input) => input,
        },
        actions: [
          {
            variant: "btn-green",
            label: this.labels.deselect,
            styles: { padding: "8px 15px", fontWeight: "700" },
            show: (data) =>
              this.data.relatedModule === data._id ? true : false,
            action: () => (this.data.relatedModule = ""),
          },
          {
            variant: "btn-grey",
            label: this.labels.select,
            styles: { padding: "8px 15px", fontWeight: "700" },
            show: (data) =>
              this.data.relatedModule !== data._id ? true : false,
            action: this.selectTableItem,
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("monetizationBundle", {
      getMonetizationBundleList: "getMonetizationBundleList",
    }),
    ...mapActions("_module", {
      getContentList: "getContentList",
    }),
    dropDownChanged(id) {
      this.$router.push({
        query: {
          parent: null,
          monetization: id,
        },
      });
    },
    selectTableItem(data) {
      this.data.relatedModule = data._id;
    },
    getModulesMt(args) {
      let data = {
        parent: null,
        monetization: null,
      };

      if (args && args.parent) data.parent = args.parent;
      if (args && args.monetization) data.monetization = args.monetization;

      this.getContentList(data)
        .then((res) => {
          if (res.success) {
            this.parents = res.data.parents;
            this.moduleList = res.data.modules.map((item) => {
              for (let i = 0; i < item.authors.length; i++) {
                item.authors[i] = item.authors[i].username;
              }

              let obj = { ...item, used: [] };
              ["links", "copied"].forEach((key) => {
                let btnLabel = this.labels.copied;
                if (key === "links") btnLabel = this.labels.linked;
                item.contentMeta.used[key].forEach((el) => {
                  obj.used.push({
                    _id: el._id,
                    label: el.title,
                    actions: [
                      {
                        type: "icon",
                        icon: "custom-external-link",
                        link: { name: "module-editor", params: { id: el._id } },
                      },
                      {
                        type: "button",
                        label: btnLabel,
                        variant: "btn-navy",
                        action: () => {},
                      },
                    ],
                  });
                });
              });
              console.log("Final obj is:", obj);
              return obj;
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    getMBList() {
      this.getMonetizationBundleList()
        .then((res) => {
          if (res.success) {
            console.log(res);
            this.MBList = [];
            res.data.data.forEach((item) => {
              let obj = {
                value: item._id,
                label: item.title,
              };
              if (item.status === 0) obj.disabled = true;
              else obj.disabled = false;

              if (this.MBList.length == 0) {
                this.$router.push({
                  query: { parent: null, monetization: obj.value },
                });
              }

              this.MBList.push(obj);
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    breadcrumbSymbol(array, index) {
      if (index + 1 === array.length) return "<span> » </span>";

      return "";
    },
    loadParent(id) {
      if (this.$route.query.parent == id) {
        this.$router.push({
          query: {
            parent: null,
            monetization: this.$route.query.monetization,
          },
        });
      } else {
        this.$router.push({
          query: {
            parent: id,
            monetization: this.$route.query.monetization,
          },
        });
      }
    },
  },
  mounted() {
    this.getMBList();
  },
  watch: {
    $route() {
      this.getModulesMt({
        parent: this.$route.query.parent,
        monetization: this.$route.query.monetization,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.select-content {
  &__top {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__parents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h5 {
      cursor: pointer;
      transition: all 300ms ease-out;

      &:hover {
        color: $purple;
      }
    }
    .span {
      display: inline-block;
    }
  }

  ::v-deep .lable-button-item {
    button {
      box-shadow: unset;
      filter: unset;
      opacity: unset;
    }
  }
}
</style>
