<template>
	<Popup
		class="create-sub-module"
		:modal-id="modalId"
		:on-close="onModalClose"
		:size="modalSize"
		custom-class="gray-header"
		:toggle-status="toggleStatus"
		:x-close-btn="false"
	>
		<template v-slot:header>
			<div class="create-sub-module__header">
				<div class="title">{{ title }}</div>
				<Button
					v-if="viewStep !== 1"
					:label="labels.back_to_basic_input"
					variant="btn-navy"
					:action="() => viewStep = 1"
				/>
			</div>
		</template>

		<template v-slot:body>
			<Step_1
				class="create-sub-module__body"
				v-if="viewStep === 1"
				:show-except-and-free="parents.length ? false : true"
				:data="mainData"
				:card-color="singleModule.color"
				:labels="labels"
				:modulePageData = "modulePageData"
			/>

			<Step_2
				class="create-sub-module__body justify-content-center"
				v-else-if="viewStep === 2"
				:action="( index ) => contentKindValue = index"
				:selected="contentKindValue"
				:labels="labels"
			/>

			<Step_3
				class="create-sub-module__body"
				v-else-if="viewStep === 3"
				:data="selectModuleData"
				:labels="labels"
			/>

		</template>

		<template v-slot:footer>
			<!-- <Button
				v-if="viewStep !== 3"
				:label="labels.select_content_type"
				variant="btn-purple"
				style="fontWeight: 600"
				:action="selectContentTypeAction"
			/> -->
			<FormulateInput
				v-if="( contentKindValue === 0 && viewStep === 1 ) || ( contentKindValue > 0 && viewStep !== 3 )"
				class="submit btn-purple"
				type="submit"
				form="create-sub-module-submit"
				:label="labels.select_content_type"
				@click="selectContentTypeAction"
			/>
			<Button
				:label="labels.cancel"
				variant="btn-outline-purple"
				data-dismiss="modal"
				style="fontWeight: 600"
				:action="onModalClose"
			/>
			<Button
				v-if="viewStep === 3 || ( contentKindValue === 0 && viewStep === 2 )"
				:disabled="createBtnDisabled"
				:label="labels.create"
				variant="btn-purple"
				style="fontWeight: 600"
				:action="createModule"
			/>
		</template>
	</popup>
</template>

<script>
import Popup from '@/components/Popups/Popup';
import Button from '@/components/Buttons/Button';
import Step_1 from "@/views/Dashboard/Module/CreateSubModule/Step_1.vue";
import Step_2 from "@/views/Dashboard/Module/CreateSubModule/Step_2.vue";
import Step_3 from "@/views/Dashboard/Module/CreateSubModule/Step_3.vue";

import { mapActions , mapGetters} from "vuex";

export default {
	name: 'CreateSubContent',
	components: {
		Popup,
		Button,
		Step_1,
		Step_2,
		Step_3,
	},
	props: {
		modalId: {
			type: String,
			required: true
		},
		modulePageData: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			parents: [],
			viewStep: 1,
			toggleStatus: false,
			createBtnDisabled: false,
			selectModuleData: {
				relatedModule: '',
			},
			contentKindValue: 0,
			mainData: {
				title: '',
				description: '',
				excerpt: '',
				free: false,
			},
		}
	},
	computed: {
		...mapGetters('helper', ['labels']),
		title() {
			let output = this.labels.create_new_module;
			if ( this.viewStep > 1 ) output = this.labels.create_new_sub_module;
			return output;
		},
		modalSize() {
			let output = 'lg';
			if ( this.viewStep === 3 ) output = 'xl';
			return output;
		},
		singleModule() {
			return this.$store.state._module.singleData;
		},
	},
	methods: {
        ...mapActions( 'notification', {
            addNotification: 'addNotification',
        } ),
        ...mapActions( '_module', {
            create: 'create',
        } ),
		...mapActions('_module', {
			getSingle: 'getSingle',
		}),
		onModalClose() {
			this.$router.push( { name: 'module-editor' } );
		},
		selectContentTypeAction() {
			if(this.parents.length){
				if(this.mainData.title && this.mainData.description){
					this.viewStep += 1;
				}
			}
			else {
				if(this.mainData.title && this.mainData.description && this.mainData.excerpt){
					this.viewStep += 1;
				}
			}
		},
		getSingleMt() {
			this.getSingle( { id: this.$route.params.id } )
				.then( res => {
					if ( res.success ) {
						this.parents = res.data.parents
					} else if ( res.error ) {
						this.addNotification( { variant: 'danger', msg: res.error } );
					}
				} )
				.catch( err => console.log( err ) );
		},
		createModule() {
			this.createBtnDisabled = true;
			let data = {
				...this.mainData,
				color: this.singleModule.color,
				kind: this.contentKindValue,
				parent: this.$route.params.id,
			}
			if ( this.selectModuleData.relatedModule ) data.related = this.selectModuleData.relatedModule;

			if ( [1,2].includes( this.contentKindValue) && !this.selectModuleData.relatedModule ) {
				this.addNotification( { variant: 'warning', msg: this.labels.no_content_selected } );
				this.createBtnDisabled = false;
			} else {
				console.log(data);
				this.create( data )
				.then( res => {
					if ( res.success ) {
						this.$emit( 'saved' );
						this.toggleStatus = false;
						this.addNotification( { variant: 'success', msg: res.msg, labels: this.labels } );
					} else if ( res.error ) {
						this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels } );
					}
					return res;
				} )
				.then( res => {
					if ( res.success ) {
						this.$router.push( { name: 'module-editor' } );
					}
					this.createBtnDisabled = false;
				} )
				.catch( err => console.log( err ) );
			}
		},
	},
	mounted() {
		this.getSingleMt();
		this.toggleStatus = true;

		let element = document.querySelector('.modal-backdrop');
		if ( element ) {
            element.remove();
            document.querySelector('body').classList.remove('modal-open');
        }
		// this.$(`#${this.modalId}`).modal('show');
	},
	beforeDestroy() {
		let element = document.querySelector('.modal-backdrop');
		if ( element ) {
            element.remove();
            document.querySelector('body').classList.remove('modal-open');
        }
		this.toggleStatus = false;
	},
}
</script>

<style lang='scss' scoped>

.create-sub-module {

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.title {
			line-height: 30px;
		}

		button {
			padding: 3px 20px 5px;
			vertical-align: center;
		}
	}

	&__body {
		::v-deep .formulate-input {

			.formulate-input-wrapper {

				label {
					font-family: "Oswald", sans-serif;
					// margin-bottom: 10px;
				}

				.formulate-input-element {
					max-width: 100%;
				}
			}

			&.horizontal-label-input {
				.formulate-input-wrapper {
					display: flex;
					align-items: center;
					gap: 3rem;

					.formulate-input-element {
						flex: 1 1 auto;
					}
				}
			}
		}
	}

	::v-deep .modal-content {
		.modal-header {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	::v-deep .formulate-input[data-type="submit"] {
		padding: 0;
	    margin: 0.25rem;
        background-color: transparent;
    	border: none;


		.formulate-input-element {
			margin-bottom: 0;
		}

		button {
			padding: 11px 20px;
			font-size: 0.875rem;
			line-height: 1.5;
			font-weight: 600;

			&:hover {
				filter: brightness(80%);
			}
		}
	}

}

</style>