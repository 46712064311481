<template>
  <div class="dropdown">
    <FormulateInput
      :key="localValue"
      type="select"
      id="formulate-input-option"
      :value="localValue"
      :options="list"
      :placeholder="placeholder"
      :label="label"
      @input="valueChanged"
    />
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    list: {
      type: [Array, Object],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  computed: {
    localValue() {
      if (this.value) return this.value;
      else if (this.placeholder) return "";
      else if (this.list && Array.isArray(this.list) && this.list.length) {
        if (this.list[0].value) return this.list[0].value;
        else if (typeof this.list[0] === "string") return this.list[0];
      } else if (this.$isObject(this.list) && Object.keys(this.list).length) {
        return this.list[Object.keys(this.list)[0]];
      }
      return "";
    },
  },
  methods: {
    valueChanged(value) {
      this.$emit("changed", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.dropdown {
  max-width: 100%;
  max-height: 40px;
  transition: all 0.3s ease;

  @media (min-width: 768px) {
    max-width: 342px;
  }

  @media (min-width: 1600px) {
    max-width: 380px;
    max-height: 46px;
  }

  ::v-deep {
    .formulate-input {
      background-color: $white;

      &-element {
        max-width: 100%;

        &::before {
          border-top-color: $light_gray;
        }
      }
    }

    .formulate-input-element {
      margin-bottom: 0;

      select {
        // height: 40px;
        font-size: 0.875rem;
        border-color: $light_gray;
        border-radius: 0.313rem;
        font-size: 0.9rem;
        line-height: 1.2em;
        padding: 10px 40px 10px 15px;

        @media (min-width: 1600px) {
          // height: 46px;
          // font-size: 1rem;
          padding: 0.75rem 2.5rem 0.75rem 0.875rem;
        }

        &:hover,
        &:focus {
          border-color: $purple;
        }
      }
    }
  }
}
</style>
