var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{staticClass:"first-step",attrs:{"id":"create-sub-module-submit"}},[_c('FormulateInput',{staticClass:"horizontal-label-input",attrs:{"type":"text","name":"title","label":_vm.labels.title,"error-behavior":"blur","validation":"required","validation-messages":{
      required: ((_vm.labels.title) + " " + (_vm.labels.required.toLowerCase())),
    }},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"description-title"},[_vm._v(_vm._s(_vm.labels.description))]),_c('FormulateInput',{attrs:{"type":"text-editor","name":"description","value":_vm.data.description,"error-behavior":"blur","validation":"required","validation-messages":{
          required: ((_vm.labels.description) + " " + (_vm.labels.required.toLowerCase())),
        }},on:{"changed":_vm.textEditorChanged}}),(_vm.showExceptAndFree)?_c('FormulateInput',{attrs:{"type":"textarea","name":"excerpt","label":_vm.labels.excerpt,"error-behavior":"blur","validation":"required","validation-messages":{
          required: ((_vm.labels.excerpt) + " " + (_vm.labels.required.toLowerCase())),
        }},model:{value:(_vm.data.excerpt),callback:function ($$v) {_vm.$set(_vm.data, "excerpt", $$v)},expression:"data.excerpt"}}):_vm._e(),(
          _vm.showExceptAndFree &&
          (_vm.modulePageData.hasRole('super') ||
            this.modulePageData.hasRole('creator'))
        )?_c('Checkbox',{attrs:{"disabled":false,"label":_vm.labels.free,"value":_vm.data.free,"name":"free"},on:{"changed":_vm.checkboxChanged}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }